import React from 'react';
import uuid4 from 'uuid/v4';
import './RollHistory.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

// Component to display the roll history
class RollHistory extends React.Component {
    
    // Constructor
    constructor(props) {
        super(props);
        this.listHistory = this.listHistory.bind(this);
    }

    // Creates a history list
    listHistory() {
        const l = this.props.history.reverse().map((h) => {
            const ll = h.rolls.map((r) => {
                return (<span className="rollEntry" key={uuid4()}>{r}</span>);
            });
            return (
                <div key={uuid4()} className="row bg-light my-1 p-1 rounded">
                    <div key={uuid4()} className="col-sm-2"><span className="badge badge-primary">{h.dice}</span> <span className="badge badge-warning">Total: {h.total}</span></div>
                    <div className="col-sm-10"><strong>Rolls: </strong>{ll}</div>
                </div>);
        });
        return (<div>{l}</div>);
    }
    
    // Render the component
    render() {
        return (
            <div>
                <div className="media">
                    <button type="button" className="btn btn-danger" onClick={this.props.onClearClick}><FontAwesomeIcon icon={faTrash} /> Clear</button>
                    <div className="media-body"><h2 className="pl-3">Roll History</h2></div>
                </div>

                {this.listHistory()}
            </div>
        );
    }

}

export default RollHistory;