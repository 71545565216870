import React from 'react';
import RollForm from './Components/RollForm';
import RollHistory from './Components/RollHistory';
import LatestRoll from './Components/LatestRoll';
import Footer from './Components/Footer';
import Heading from './Components/Heading';

// Main App Component
class App extends React.Component {

    // Constructor
    constructor(props) {
        super(props);
        this.state = {
            history : [],   // Contains a history of roll results.
            latest : null,  // The latest roll result.
        };
        this.onResultChange = this.onResultChange.bind(this);
        this.onClearClick = this.onClearClick.bind(this);
    }

    // Handles when the results change.
    onResultChange(result) {
        this.setState({
            latest : Object.assign(result),
            history : [...this.state.history, Object.assign(result)],
        });
    }

    // Handles when the user clears the history.
    onClearClick(e) {
        this.setState({
            history : [],
        });
    }

    // Renders the Component
    render() {
        return (
            <div className="container">
                <Heading />
                <hr/>
                <LatestRoll latest={this.state.latest}/>
                <hr/>
                <RollForm onResultChange={this.onResultChange}/>
                <hr/>
                <RollHistory history={this.state.history} onClearClick={this.onClearClick}/>
                <hr />
                <Footer />
            </div>
        );
    }
}

export default App;