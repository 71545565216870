import React from 'react';
import './LatestRoll.css';
import uuid4 from 'uuid/v4';

// Component which displays details of the latest roll
class LatestRoll extends React.Component {

    // Constructor
    constructor(props) {
        super(props);
        this.listRolls = this.listRolls.bind(this);
    }

    // Generate a list of rolls
    listRolls() {
        let rList = this.props.latest.rolls.map((roll) => {
            return (
                <div className="col-sm m-1" key={uuid4()}>
                    <div className="d-flex align-items-center dice-box">
                        <div className="mx-auto text-center roll-box">{roll}</div>
                    </div>
                </div>
            );
        });
        return rList;
    }

    // Render the component
    render() {
        
        // Displays a notice if there are no rolls
        if (!this.props.latest) {
            return (
                <div>
                    <div className="row justify-content-sm-center">
                        <div className="text-center col-sm"><p>No Rolls</p></div>
                    </div>
                </div>
            );
        }

        // Render the rolls
        else {
            return (
                <div>
                    <h4>Latest Roll</h4>
                    <h4><span class="badge badge-primary">{this.props.latest.dice}</span> <span class="badge badge-warning">Total: {this.props.latest.total}</span></h4>
                    <div className="row">
                        {this.listRolls()}
                    </div>
                </div>
            );
        }
    }

}

export default LatestRoll;