import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiceD20 } from '@fortawesome/free-solid-svg-icons';

// Component for header
class Heading extends React.Component {

    // Render the component
    render() {
        return (<heading><h1 className="title-header"><FontAwesomeIcon id="head-d20" icon={faDiceD20} /> Safty Dice Roller</h1></heading>);
    };
}

export default Heading;