import React from 'react';
import './RollForm.css';
import $ from 'jquery';

// Component that handles the form to perform a new roll
class RollForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            number: "1",    // The number of dice to roll
            sides : "4",    // The sides of the die to roll (valid are D4, D6, D8, D10, D12, and D20)
            result : {},    // Object to store the result of the roll
        };
        this.handleFormChange = this.handleFormChange.bind(this);
        this.handleNumberChange = this.handleNumberChange.bind(this);
        this.rollDice = this.rollDice.bind(this);
    }
    
    // Handles when the sides are changed in the form
    handleFormChange(e) {
        this.setState({
            sides : e.target.value,
        });
    }

    // Handles when the number changes in the form
    handleNumberChange(e) {
        
        // (Fallback from slider) if it isn't valid range, disable roll button
        if (e.target.value < 1 || e.target.value > 10) { 
            $('#roll-button').attr("disabled", true);
        }

        // If previously disabled but now OK, enable roll button
        else if ($('#roll-button').attr("disabled")) {
            $('#roll-button').attr("disabled", false);
        }

        this.setState({
            number : e.target.value,
        });
    }

    // Performs the die roll
    rollDice() {
        let rolls = []; // Temp container for rolls
        let total = 0;  // Temp totaller
        
        for(let i = 0; i < parseInt(this.state.number); ++i) {
            let rollRes = Math.floor(Math.random() * (parseInt(this.state.sides)) + 1);
            rolls.push(rollRes);
            total += rollRes;
        }

        const result = {
                dice : `${this.state.number}d${this.state.sides}`,  // The dice roll (ie 2d4)
                rolls : [...rolls], // The actual rolls
                total : total,  // The total sum of all rolls added together
        };

        this.setState({
            result : result,
        });

        // Calls parent element's onResultChange
        this.props.onResultChange(result);
    }

    // Render the component
    render() {
        return (
            <section className="row">
                <div className="col-sm m-1 rounded-right p-3 bg-light align-middle justify-content-center">
                    <h6 className="text-secondary">Rolling:</h6>
                    <div className="d-block m-auto text-center align-middle font-weight-bold dice-text">{this.state.number}d{this.state.sides}</div>
                </div>
                <div className="col-sm-10 rounded-right m-1 p-3 bg-light">
                    <form>
                        <label className="d-block align-middle">Dice (1-10): <input type="range" min="1" max="10" value={this.state.number} onChange={this.handleNumberChange}/></label>
                        <label className="d-block align-middle">Sides: <select value={this.state.sides} onChange={this.handleFormChange}>
                            <option value="4">d4</option>
                            <option value="6">d6</option>
                            <option value="8">d8</option>
                            <option value="10">d10</option>
                            <option value="12">d12</option>
                            <option value="20">d20</option>
                        </select></label>
                        <button id="roll-button" className="btn btn-primary btn-block" type="button" onClick={this.rollDice}>Roll!</button>
                    </form>
                </div>
            </section>
        );
    }
}

export default RollForm;