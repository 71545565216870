import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';

const twitterStyle = {
    color : "#00aced",
}

// Footer component
class Footer extends React.Component {

    // Render the component
    render() {
        return (
            <footer className="text-center">
            <p><FontAwesomeIcon icon={faTwitter} style={twitterStyle} /> <a href="https://twitter.com/saftykuma">Follow me on Twitter</a></p>
            <p>Safty Die Roller &copy;2019 <a href="https://safty.me">Safty.me</a></p>
            </footer>  
        );
    }
}

export default Footer;